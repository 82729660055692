import { useEffect } from "react";
import { Col, Container, Image, Row, Stack } from "react-bootstrap";
import AOS from 'aos';
import 'aos/dist/aos.css';
const leadership = "https://futurristic.s3.us-east-1.amazonaws.com/image/collab/Collab-leadership01.png"  ;
const guide = "https://futurristic.s3.us-east-1.amazonaws.com/image/collab/Collab-guide02.png" ;
const collab = "https://futurristic.s3.us-east-1.amazonaws.com/image/collab/Collab.png"  ;
const user = "https://futurristic.s3.us-east-1.amazonaws.com/image/collab/Collab-support04.png";


const CollabHowWorks = () => {

    useEffect(() => {
        AOS.init({ duration: 1000 })
    });

    return (
        <Container data-aos="fade-up">

            <h1 className='text-center fontWeight-800' style={{ color: '#0F75D6' }}>How It Works: Let’s Break It Down!</h1>
            <h5 className="mb-3">Here's how it all comes together—let's dive in!</h5>

            <div className="CollabContainer" data-aos='fade-up'>
                <Row className="justify-content-center g-3">
                    <Col lg={3} md={6} sm={6} xs={12}>
                        <Stack direction="vertical" gap={4} className='justify-content-center align-items-center'>
                            <div className="collabBoxIcon"><Image src={user} width={50} height={50} alt="User Setup Icon" /></div>
                            <div className="CollabBox ">
                                <div className="HeadingBox "><span className="fw-bold text-white">User Setup</span></div>
                                <h5 className='text-center'>The user wears a VR headset and launches the Collab app.</h5>
                            </div>
                        </Stack>
                    </Col>
                    <Col lg={3} md={6} sm={6} xs={12}>
                    <Stack direction="vertical" gap={4} className='justify-content-center align-items-center'>
                        <div className="collabBoxIcon"> <Image src={leadership} width={50} height={50} alt="Expert access Icon" /> </div>
                        <div className="CollabBox ">
                            <div className="HeadingBox "><span className="fw-bold text-white">Expert Access </span></div>
                            <h5 className='text-center'>The expert opens the Collab app on a mobile device or tablet.</h5>
                        </div>
                        </Stack>
                    </Col>
                    <Col lg={3} md={6} sm={6} xs={12}>
                    <Stack direction="vertical" gap={4} className='justify-content-center align-items-center'>
                                            <div className="collabBoxIcon"> <Image src={collab} width={50} height={50} alt="Real-Time Collab Icon" /></div>
                        <div className="CollabBox ">
                            <div className="HeadingBox "><span className="fw-bold text-white">Real-Time Collab</span></div>
                            <h5 className='text-center'>Connect instantly via call/video on the Collab Suite application.</h5>
                        </div>
                        </Stack>
                    </Col>
                    <Col lg={3} md={6} sm={6} xs={12}>
                    <Stack direction="vertical" gap={4} className='justify-content-center align-items-center'>
                        <div className="collabBoxIcon"> <Image src={guide} width={50} height={50} alt="Guide Support Icon" /></div>
                        <div className="CollabBox ">
                            <div className="HeadingBox "><span className="fw-bold text-white">Guided Support</span></div>
                            <h5 className='text-center'>Experts then provide real-time guidance and support.</h5>
                        </div>
                        </Stack>
                    </Col>
                </Row>
            </div>
        </Container>
    );
}
export default CollabHowWorks;