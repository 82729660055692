import { Card, Container, Pagination, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Blog } from "../BlogData/BlogData";
import { IoIosArrowForward } from "react-icons/io";
import { useState } from "react";

export const BlogCards = () => {
    const navigate = useNavigate();

    // const handleCardClick = (data, url) => {
    //     navigate(url, { state: data.details });
    // }

    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(Blog.length / 5);

    let items = [];
    if (currentPage > 1) {
        items.push(<Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} />)
    }
    for (let num = 1; num <= totalPages; num++) {
        items.push(
            <Pagination.Item key={num} active={num === currentPage} onClick={() => setCurrentPage(num)}>
                {num}
            </Pagination.Item>)
    }
    if (currentPage < totalPages) {
        items.push(<Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} />)
    }

    const indexOfLastBlog = currentPage * 6;
    const indexOfFirstBlog = indexOfLastBlog - 6;
    const blog = Blog.slice().reverse();
    const currentBlog = blog.slice(indexOfFirstBlog, indexOfLastBlog);

    const handleCardClick = (data, url) => {
        window.open(`/insights/${url}`, '_blank'); // Open a new tab

    };

    return <>
        <Container>
            <h3 className="text-start"><IoIosArrowForward />Latest Highlights</h3>

            <Row className='g-4'>

                {currentBlog.map((data, index) => {
                    return (
                        <Col md={4} sm={6}>
                            <Card className="custom-blogcard p-3 border-0 shadow cursor-pointer" key={index} onClick={() => handleCardClick(data, data.block.url)} >
                                <div className='hover-effect'>
                                    <Card.Img variant="top" className="rounded img-height-sm" src={data.block.image} alt={`blog card image ${index}`} />
                                </div>
                                <Card.Body className="text-start">
                                    <p className="mb-1">{data.block.date}</p>
                                    <Card.Title className='text-bold '>{data.block.title}</Card.Title>
                                    <Card.Text className="text-secondary">
                                        {data.block.description.slice(0, 100) + "..."}<Link to='/'>see more</Link>
                                    </Card.Text>
                                </Card.Body>

                            </Card>
                        </Col>
                    );
                })}
            </Row>
            <Pagination size='sm' className='float-end p-3'>{items}</Pagination>
        </Container>
    </>
}