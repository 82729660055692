import React from 'react'
import { Container, Row, Col, Stack } from 'react-bootstrap';
const benefit1 = 'https://futurristic.s3.amazonaws.com/image/video/Icons/ARVRXR-Game-benefit01.png'
const benefit2 = 'https://futurristic.s3.amazonaws.com/image/video/Icons/ARVRXR-Game-benefit02.png'
const benefit3 = 'https://futurristic.s3.amazonaws.com/image/video/Icons/ARVRXR-Game-benefit03.png'
const benefit4 = 'https://futurristic.s3.amazonaws.com/image/video/Icons/ARVRXR-Game-benefit04.png'
const benefit5 = 'https://futurristic.s3.amazonaws.com/image/video/Icons/ARVRXR-Game-benefit05.png'
const benefit6 = 'https://futurristic.s3.amazonaws.com/image/video/Icons/ARVRXR-Game-benefit06.png'


export const BenefitsARVRXR = () => {
    return (
        <>
            <Container  className="pt-5">
                <Row>
                    <Col>
                        <h1 className="text-center fontWeight-800">
                        Here are the Potential Benefits of Game Development Services
                        </h1>
                        <p className=" text-center text-grey-500 fontSize-20">
                        Discover how interactive game development can turn playtime into prime-time success.
                            </p>
                    </Col>
                </Row>
                <Row className="g-3">
                     <Col md={4} xs={6} lg={2}>
                        <div className="BenefitContent1">
                            <img src={benefit1} className="img-fluid benefitIcons" alt="ARVRXRBenefit-01-futurristic" />
                                <Stack direction='horizontal' className='align-items-center h-50'><p>Enhanced user engagement</p></Stack>
                        </div>
                    </Col>
                     <Col md={4} xs={6} lg={2}>
                        <div className="BenefitContent2">
                            <img src={benefit2} className="img-fluid benefitIcons" alt="ARVRXRBenefit-02-futurristic" />
                                <Stack direction='horizontal' className='align-items-center h-50'><p>Real-world simulation</p></Stack>
                        </div>
                    </Col>
                     <Col md={4} xs={6} lg={2}>
                        <div className="BenefitContent1">
                            <img src={benefit3} className="img-fluid benefitIcons" alt="ARVRXRBenefit-03-futurristic" />
                                <Stack direction='horizontal' className='align-items-center h-50'><p>Immersive learning experiences</p></Stack>
                        </div>
                    </Col>
                     <Col md={2} xs={6}>
                        <div className="BenefitContent2">
                            <img src={benefit4} className="img-fluid benefitIcons" alt="ARVRXRBenefit-04-futurristic" />
                                <Stack direction='horizontal' className='align-items-center h-50'><p className="mb-2">Adaptive training environments</p></Stack>
                        </div>
                    </Col>
                     <Col md={4} xs={6} lg={2}>
                        <div className="BenefitContent1">
                            <img src={benefit5} className="img-fluid benefitIcons" alt="ARVRXRBenefit-05-futurristic" />
                                <Stack direction='horizontal' className='align-items-center h-50'><p className="mb-2">Personalized customer interactions</p></Stack>
                        </div>
                    </Col>
                     <Col md={4} xs={6} lg={2}>
                        <div className="BenefitContent2">
                            <img src={benefit6} className="img-fluid benefitIcons" alt="ARVRXRBenefit-06-futurristic" />
                                <Stack direction='horizontal' className='align-items-center h-50'><p className="mb-2">Distinct Market Position </p></Stack>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
