import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const ServiceMeta = () => {
    return (
        <>
            <Container className='my-5'>
                <Row>
                    <Col>
                        <h1 className="fontWeight-800">
                            Metaverse Services and Solutions
                        </h1>
                        <p className="text-center text-grey-500 fontSize-20">
                            Ready to break the boundaries of reality? Explore these premier <Link to='/' target='_blank' > metaverse services & solutions,</Link> custom-built to guide your business into the next frontier.
                        </p>
                    </Col>
                </Row>
                <Row className="g-4">
                    <Col lg={4} md={6} sm={12} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src="https://futurristic.s3.amazonaws.com/image/video/Icons/Meta-Icon01.png" className="img-fluid Icon-24" alt="Metaverse-service-01-futurristic" />
                                <h5 className="text-center fontWeight-800">Metaverse Consulting</h5>
                                <p className="text-justify text-grey-500">Reach your Metaverse goals faster and smarter with Futurristic’s strategic advice, technical know-how, and experience-based problem-solving approach  </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                    <Col lg={4} md={6} sm={12} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src="https://futurristic.s3.amazonaws.com/image/video/Icons/Meta-Icon02.png" className="img-fluid Icon-24" alt="Metaverse-service-02-futurristic" />
                                <h5 className="text-center fontWeight-800">Metaverse Games</h5>
                                <p className="text-justify text-grey-500"> Craft a captivating gaming world with Futurristic. Design and develop experiences that redefine entertainment & forge lasting connections within the metaverse. </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                    <Col lg={4} md={6} sm={12} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src="https://futurristic.s3.amazonaws.com/image/video/Icons/Meta-Icon03.png" className="img-fluid Icon-24" alt="Metaverse-service-03-futurristic" />
                                <h5 className="text-center fontWeight-800">Metaverse Applications</h5>
                                <p className="text-justify text-grey-500">
                                    Design and develop groundbreaking Metaverse applications with complete customization. Craft experiences that shatter limitations, ignite user engagement, and unlock new realities.
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                    <Col lg={4} md={6} sm={12} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src="https://futurristic.s3.amazonaws.com/image/video/Icons/Meta-Icon04.png" className="img-fluid Icon-24" alt="Metaverse-service-04-futurristic" />
                                <h5 className="text-center fontWeight-800">Simulations, Training, And Digital Twins</h5>
                                <p className="text-justify text-grey-500">
                                    With Futurristic, craft realistic scenarios for workforce development, process optimization, and informed decision-making. Explore and expedite peerless efficiency and innovation. </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                    <Col lg={4} md={6} sm={12} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src="https://futurristic.s3.amazonaws.com/image/video/Icons/Meta-Icon05.png" className="img-fluid Icon-24" alt="Metaverse-service-05-futurristic" />
                                <h5 className="text-center fontWeight-800">Metaverse Marketplaces</h5>
                                <p className=" text-justify text-grey-500">
                                    Provide the infrastructure for a thriving virtual economy with Futurristic. Develop custom marketplaces for seamless buying, selling, and interaction within the Metaverse.
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                    <Col lg={4} md={6} sm={12} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src="https://futurristic.s3.amazonaws.com/image/video/Icons/Meta-Icon06.png" className="img-fluid Icon-24" alt="Metaverse-service-06-futurristic" />
                                <h5 className="text-center fontWeight-800">Metaverse Decentralized Platforms</h5>
                                <p className="text-justify text-grey-500">
                                    Design secure, blockchain-enabled platforms for users-owned metaverses with Futurristic. Users govern, manage assets, and collaborate, creating a vibrant decentralized ecosystem.
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                    <Col lg={4} md={6} sm={12} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src="https://futurristic.s3.amazonaws.com/image/video/Icons/Meta-Icon07.png" className="img-fluid Icon-24" alt="Metaverse-service-07-futurristic" />
                                <h5 className="text-center fontWeight-800">Metaverse Digital Economies (NFT, Blockchain)</h5>
                                <p className="text-justify text-grey-500">
                                    Own the virtual economy. Craft NFT & blockchain-powered marketplaces for seamless trading of crypto, NFTs, and digital assets within your Metaverse.
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                    <Col lg={4} md={6} sm={12} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-4 align-items-center p-3">
                                <img src="https://futurristic.s3.amazonaws.com/image/video/Icons/Meta-Icon08.png" className="img-fluid Icon-24" alt="Metaverse-service-08-futurristic" />
                                <h5 className="fontWeight-800 ">Metaverse 2D/3D Art, Avatar Services</h5>
                                <p className="text-justify text-grey-500">
                                    Visualize your metaverse. Develop stunning, high-fidelity 2D/3D art, avatars, and assets specifically designed for the metaverse with Futurristic's innovative, expert team.
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                    <Col lg={4} md={6} sm={12} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-4 align-items-center p-3">
                                <img src="https://futurristic.s3.amazonaws.com/image/video/Icons/Meta-Icon09.png" className="img-fluid Icon-24" alt="Metaverse-service-09-futurristic" />
                                <h5 className=" fontWeight-800">Metaverse Integration Services</h5>
                                <p className="text-justify text-grey-500">
                                    Ensure smooth data synchronization, eliminate silos. Connect your existing systems to the metaverse seamlessly, creating a unified and powerful experience.
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                    <Col lg={4} md={6} sm={12} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src="https://futurristic.s3.amazonaws.com/image/video/Icons/Meta-Icon010.png" className="img-fluid Icon-24" alt="Metaverse-service-10-futurristic" />
                                <h5 className="text-center fontWeight-800">Metaverse For Enterprise</h5>
                                <p className="text-justify text-grey-500">
                                    Start collaborating seamlessly. Deliver impactful training and optimize workflows with secure, customizable metaverse solutions for your enterprise.
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
