
// import React, { useEffect, useRef, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import { Blog } from "../BlogData/BlogData";
// import { Stack } from 'react-bootstrap';
// import Header from '../../components/Header/Header';
// import Footer from '../../components/Footer/Footer';

// export const BlogDetail = () => {
//     const { url } = useParams(); // Get the blog URL parameter
//     const contactUsRef = useRef(null);
//     const scrollToRefs = {
//         contactUsRef,
//     };
//     const [blog, setBlog] = useState(null);

//     useEffect(() => {
//         // Find the blog by URL (you can use the `url` to find the matching blog)
//         const matchedBlog = Blog.find(blog => blog.block.url === url); // Assuming pageURL is unique
//         setBlog(matchedBlog);
//     }, [url]);

//     console.log(blog);
//     if (!blog) {
//         return <div>Loading...</div>;
//     }

//     return (
//         <Stack direction='vertical' gap={5}>
//             <Header bgtertiary={true} blog={true} />
//             <div className='mt-5' dangerouslySetInnerHTML={{ __html: blog.details }}></div>
//             <Footer scrollToRefs={scrollToRefs} />
//         </Stack>
//     );
// };

// // --------------previous code------------------------------------------

// // import { useEffect, useRef, useState } from "react";
// // import { useLocation } from "react-router-dom";
// // import Header from "../../components/Header/Header";
// // import Footer from "../../components/Footer/Footer";
// // import { Stack } from "react-bootstrap";

// // export const BlogDetail = () => {
// //     const location = useLocation();
// //     const [details, setDetails] = useState("");
// //     const contactUsRef = useRef(null);
// //     const scrollToRefs = {
// //         contactUsRef,
// //     };


// //     useEffect(() => {
// //         if (location && location?.state) {
// //             setDetails(location?.state);
// //         }
// //     }, [location])



// //     return (
// //         <>
// //             <Stack direction='vertical' gap={5}>
// //                 <Header bgtertiary={true} blog={true} />
// //                 <div className='mt-5' dangerouslySetInnerHTML={{ __html: details }}></div>
// //                 <Footer scrollToRefs={scrollToRefs} />
// //             </Stack>

// //         </>


// //     );
// // }; 

import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Blog } from "../BlogData/BlogData";
import { Stack } from "react-bootstrap";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

export const BlogDetail = () => {
  const { url } = useParams(); // Get the blog URL parameter
  const contactUsRef = useRef(null);
  const scrollToRefs = {
    contactUsRef,
  };

  const [blog, setBlog] = useState(null);

  useEffect(() => {
    // Find the blog by URL (use the `url` parameter to find the matching blog)
    const matchedBlog = Blog.find((b) => b.block.url === url);

    if (matchedBlog) {
      setBlog(matchedBlog);

      // Update meta tags dynamically
      document.title = matchedBlog.block.title;

      const metaDescription = document.querySelector("meta[name='description']");
      if (metaDescription) {
        metaDescription.setAttribute("content", matchedBlog.block.meta_description);
      }

      const linkCanonical = document.querySelector("link[rel='canonical']");
      if (linkCanonical) {
        
        linkCanonical.setAttribute("href", matchedBlog.block.pageURL);
      }
    }
  }, [url]);

  if (!blog) {
    return <div></div>;
  }

  return (
    <Stack direction="vertical" gap={5}>
      <Header bgtertiary={true} blog={true} />
      <div className="mt-5" dangerouslySetInnerHTML={{ __html: blog.details }}></div>
      <Footer scrollToRefs={scrollToRefs} />
    </Stack>
  );
};
