import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const AboutARVR = () => {
    return (
        <>
            <Container className='my-5'>
                <div className="AboutUs02">
                    <Row className="justify-content-center">
                        <Col md={12} lg={9} sm={12}>
                            <div className="AboutUsText">
                                <div className="vstack gap-3">
                                    <h1 className="text-center fontWeight-800">Delight Your Audience. Redefine Business and Interaction.</h1>
                                    <h4 className="text-center">Craft Engagement with Custom AR/VR/XR Solutions. </h4>
                                    <p className="text-center fontSize-24 text-grey-500 fontWeight-500">
                                    Leverage immersive AR, VR, XR, and MR technologies to deliver innovative, interactive experiences that tackle business challenges. From Vision Pro app development to <Link to='https://futurristic.com/fexperience/' target='_Blank'>Meta Quest developer app </Link>and  <Link to='/collab-suite' target='_Blank'> Oculus app development</Link> solutions, businesses can engage audiences in new, compelling ways. In addition, integrating technologies that allow businesses to seamlessly bring virtual experiences to the web can offer a more direct, engaging way to connect with customers.  <Link to='/web-ar-based-marketing-solutions' target='_Blank'>Web-ar based marketing solutions</Link> enhance brand interaction by providing accessible, immersive experiences that integrate with the digital landscape, creating opportunities for deeper customer involvement.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    )
}
