import React from 'react'
import { Container, Row, Col, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const AboutARVRXR = () => {
    return (
        <> 
                <Container className='my-5'>
                    <div className="AboutUs02">
                        <Row className="justify-content-center">
                            <Col md={12} lg={9} sm={12}>
                                <div className="AboutUsText">
                                    <Stack direction='vertical' gap={3}>
                                        <h1 className="text-center fontWeight-800">Struggling to Bring Your Dream Gaming World to Life?</h1>
                                        <h4 className="text-center">Witness Immersive Worlds Come to Life with Game Development </h4>
                                        <p className="text-center fontSize-24 fontWeight-500 text-secondary">
                                        Being specialized in the multidisciplinary approach needed for creating an interactive game, let’s create your custom game together. List down your requirements, and we’ll transform your ideas into a breathtaking game that not only meets but exceeds your expectations. Not only in games, if your vision is perfectly explored across all platforms, leveraging  <Link to='/mobile-web-&-enterprise-development' target='_blank'>mobile web & enterprise development services</Link> can also work for your business.
                                       
                                        </p>
                                    </Stack>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container> 
        </>
    )
}
