import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const service1 = 'https://futurristic.s3.amazonaws.com/image/video/Icons/ARVRTraining-service01.png'
const service2 = 'https://futurristic.s3.amazonaws.com/image/video/Icons/ARVRTraining-service02.png'
const service3 = 'https://futurristic.s3.amazonaws.com/image/video/Icons/ARVRTraining-service03.png'
const service4 = 'https://futurristic.s3.amazonaws.com/image/video/Icons/ARVRTraining-service04.png'
const service5 = 'https://futurristic.s3.amazonaws.com/image/video/Icons/ARVRTraining-service05.png'
const service6 = 'https://futurristic.s3.amazonaws.com/image/video/Icons/ARVRTraining-service06.png'
const service7 = 'https://futurristic.s3.amazonaws.com/image/video/Icons/ARVRTraining-service07.png'
const service8 = 'https://futurristic.s3.amazonaws.com/image/video/Icons/ARVRTraining-service08.png'
const service9 = 'https://futurristic.s3.amazonaws.com/image/video/Icons/ARVRTraining-service09.png'


export const ARVRservice = () => {
    return (
        <>
            <Container className='my-5'>
                <Row>
                    <Col>
                        <h1 className="fontWeight-800">
                        Augmented & Virtual Reality Services & Solutions
                        </h1>
                        <p className="text-center text-grey-500 fontSize-20">Find comprehensive, professional AR VR solutions tailored for seamless business transformation, improvising immersive experiences effectively. </p>
                    </Col>
                </Row>

                <Row className="g-4">
                    <Col sm={12} md={4} lg={4} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src={service1} className="img-fluid serviceIcon" alt="ARVRService-01-futurristic" />
                                <h5 className="fontWeight-800 text-center">AR/VR Consultation & Strategy Services </h5>
                                <p className="text-justify text-grey-500">Get tailored strategies from the best <Link to='/' target='_blank'>AR & VR App Development Company</Link>. Explore innovative solutions that drive engagement, improve learning outcomes, and transform user experience across various industries.</p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                    <Col sm={12} md={4} lg={4} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src={service2} className="img-fluid serviceIcon" alt="ARVRService-02-futurristic" />
                                <h5 className="fontWeight-800 text-center">AR VR MR XR</h5>
                                <p className="text-justify text-grey-500">Explore the full spectrum of reality technologies with our comprehensive AR, VR, MR, & XR services. Create tailored solutions that blend the physical and digital worlds, enabling innovative applications across various industries.</p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                    <Col sm={12} md={4} lg={4} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src={service3} className="img-fluid serviceIcon" alt="ARVRService-03-futurristic" />
                                <h5 className="fontWeight-800 text-center">AR/VR in Different Industries</h5>
                                <p className="text-justify text-grey-500">Unlock the potential of AR and VR across diverse sectors such as healthcare, retail, manufacturing, Automotive, or education. Enhance operational efficiency, improve training outcomes, & create engaging customer experiences.</p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
               
                    <Col sm={12} md={4} lg={4} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src={service4} className="img-fluid serviceIcon" alt="ARVRService-04-futurristic" />
                                <h5 className="fontWeight-800 text-center">AR/VR/XR Training & Education </h5>
                                <p className="text-justify text-grey-500">Enhance learning experiences with immersive AR/VR training solutions. Create engaging, interactive environments that facilitate knowledge retention & skill development, empowering educators & learners alike.</p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col >
                    <Col sm={12} md={4} lg={4} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src={service5} className="img-fluid serviceIcon" alt="ARVRService-05-futurristic" />
                                <h5 className="fontWeight-800 text-center">AR VR App/Software Development</h5>
                                <p className="text-justify text-grey-500">Create sophisticated AR/VR applications with a robust backend infrastructure and intuitive user interfaces. Ensure seamless navigation and optimal performance, delivering a superior experience for all. </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                    <Col sm={12} md={4} lg={4} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src={service6} className="img-fluid serviceIcon" alt="ARVRService-06-futurristic" />
                                <h5 className="fontWeight-800 text-center">AR VR Integration</h5>
                                <p className="text-justify text-grey-500">Seamlessly integrate AR/VR technologies into your existing systems to enhance user interaction & data visualization. Ensure your applications are enriched with immersive experiences, driving engagement & productivity.</p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                    <Col sm={12} md={4} lg={4} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src={service7} className="img-fluid serviceIcon" alt="ARVRService-06-futurristic" />
                                <h5 className="fontWeight-800 text-center">Virtual Showrooms & Product Demos  </h5>
                                <p className="text-justify text-grey-500">Transform customer experiences with virtual showrooms that allow users to explore products in an interactive environment. Get ideal solutions to showcase your offerings effectively, enhancing engagement & driving sales.
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                    <Col sm={12} md={4} lg={4} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src={service8} className="img-fluid serviceIcon" alt="ARVRService-06-futurristic" />
                                <h5 className="fontWeight-800 text-center">Vision Pro App Development</h5>
                                <p className="text-justify text-grey-500">Develop <Link to='/insights/Apple-Vision-Pro-App-Development-A-Look-Ahead-to-2025' target='_Blank'>Apple Vision Pro applications</Link> that harness its groundbreaking technology. With Futurristic, craft immersive, next-gen experiences that engage users, boost brand visibility, & set your business apart in the competitive market.
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                    <Col sm={12} md={4} lg={4} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src={service9} className="img-fluid serviceIcon" alt="ARVRService-06-futurristic" />
                                <h5 className="fontWeight-800 text-center">AR VR Content Creation</h5>
                                <p className="text-justify text-grey-500">Craft captivating AR and VR experiences through expert storyboarding, 360 video & imagery, photogrammetry, and 3D modeling. Enhance immersion with spatial audio, technical art, & intuitive UI/UX design.
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
