import React from 'react'
import { Container, Row, Col, Stack } from 'react-bootstrap';
const benefit1 = 'https://futurristic.s3.amazonaws.com/image/video/Icons/bockchain-benefit01.png'
const benefit2 = 'https://futurristic.s3.amazonaws.com/image/video/Icons/bockchain-benefit02.png'
const benefit3 = 'https://futurristic.s3.amazonaws.com/image/video/Icons/bockchain-benefit03.png'
const benefit4 = 'https://futurristic.s3.amazonaws.com/image/video/Icons/bockchain-benefit04.png'
const benefit5 = 'https://futurristic.s3.amazonaws.com/image/video/Icons/bockchain-benefit05.png'
const benefit6 = 'https://futurristic.s3.amazonaws.com/image/video/Icons/bockchain-benefit06.png'

export const BenefitBlock = () => {
    return (
        <>
            <Container  className="my-5">
                <Row>
                    <Col>
                        <h1 className="text-center fontWeight-800">
                            Here are the Potential Benefits of Blockchain
                        </h1>
                    </Col>
                </Row>
                <Row className="g-3">
                    <Col  md={4} xs={6} lg={2}>
                        <div className="BenefitContent1">
                            <img src={benefit1} className="img-fluid benefitIcons" alt="Blockchain-Benefit-01-futurristic" />
                               <Stack direction='horizontal' className='align-items-center h-50'><p>Enhanced Security</p></Stack>
                        </div>
                    </Col>
                    <Col  md={4} xs={6} lg={2}>
                        <div className="BenefitContent2">
                            <img src={benefit2} className="img-fluid benefitIcons" alt="Blockchain-Benefit-02-futurristic" />
                               <Stack direction='horizontal' className='align-items-center h-50'><p>Improved Transparency</p></Stack>
                        </div>
                    </Col>
                    <Col  md={4} xs={6} lg={2}>
                        <div className="BenefitContent1">
                            <img src={benefit3} className="img-fluid benefitIcons" alt="Blockchain-Benefit-03-futurristic" />
                               <Stack direction='horizontal' className='align-items-center h-50'><p>Greater Efficiency</p></Stack>
                        </div>
                    </Col>
                    <Col  md={4} xs={6} lg={2}>
                        <div className="BenefitContent2">
                            <img src={benefit4} className="img-fluid benefitIcons" alt="Blockchain-Benefit-04-futurristic" />
                                <Stack direction='horizontal' className='align-items-center h-50'><p>Decentralized Control</p></Stack>
                        </div>
                    </Col>
                    <Col  md={4} xs={6} lg={2}>
                        <div className="BenefitContent1">
                            <img src={benefit5} className="img-fluid benefitIcons" alt="Blockchain-Benefit-05-futurristic" />
                                <Stack direction='horizontal' className='align-items-center h-50'><p>Immutable Records</p></Stack>
                        </div>
                    </Col>
                    <Col  md={4} xs={6} lg={2}>
                        <div className="BenefitContent2">
                            <img src={benefit6} className="img-fluid benefitIcons" alt="Blockchain-Benefit-06-futurristic" />
                                <Stack direction='horizontal' className='align-items-center h-50'><p>Cost Savings</p></Stack>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
