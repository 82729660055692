import React from 'react'
import { Container, Row, Col, Stack } from 'react-bootstrap';
const benefit1 = 'https://futurristic.s3.amazonaws.com/image/video/Icons/mobieweb-benefit01.png'
const benefit2 = 'https://futurristic.s3.amazonaws.com/image/video/Icons/mobieweb-benefit02.png'
const benefit3 = 'https://futurristic.s3.amazonaws.com/image/video/Icons/mobieweb-benefit03.png'
const benefit4 = 'https://futurristic.s3.amazonaws.com/image/video/Icons/mobieweb-benefit04.png'
const benefit5 = 'https://futurristic.s3.amazonaws.com/image/video/Icons/mobieweb-benefit05.png'
const benefit6 = 'https://futurristic.s3.amazonaws.com/image/video/Icons/mobieweb-benefit06.png'



export const BenefitsMobileApp = () => {
    return (
        <>
            <Container className="my-5">
                <Row >
                    <Col>
                        <h1 className="text-center fontWeight-800">
                            Here are the Potential Benefits of Mobile, Web & Enterprise Development
                        </h1>
                        <p className="text-center text-grey-500 fontSize-20">Turn your tech headaches into seamless solutions and see what benefits you will get.
                        </p>
                    </Col>
                </Row>
                <Row className='g-3'>
                    <Col md={4} xs={6} lg={2}>
                        <div className="BenefitContent1">
                            <img src={benefit1} className="img-fluid benefitIcons" alt="MobileApp-benefit-01-futurristic" />
                           <Stack direction='horizontal' className='align-items-center justify-content-center h-50'><p>Enhanced User Experience</p></Stack>
                        </div>
                    </Col>
                    <Col md={4} xs={6} lg={2}>
                        <div className="BenefitContent2">
                            <img src={benefit2} className="img-fluid benefitIcons" alt="MobileApp-benefit-02-futurristic" />
                           <Stack direction='horizontal' className='align-items-center justify-content-center h-50'><p>Increased Productivity</p></Stack>
                        </div>
                    </Col>
                    <Col md={4} xs={6} lg={2}>
                        <div className="BenefitContent1">
                            <img src={benefit3} className="img-fluid benefitIcons" alt="MobileApp-benefit-03-futurristic" />
                           <Stack direction='horizontal' className='align-items-center justify-content-center h-50'><p>High Scalability</p></Stack>
                        </div>
                    </Col>
                    <Col md={4} xs={6} lg={2}>
                        <div className="BenefitContent2">
                            <img src={benefit4} className="img-fluid benefitIcons" alt="MobileApp-benefit-04-futurristic" />
                            <Stack direction='horizontal' className='align-items-center justify-content-center h-50'><p>Real-time Access</p></Stack>
                        </div>
                    </Col>
                    <Col md={4} xs={6} lg={2}>
                        <div className="BenefitContent1">
                            <img src={benefit5} className="img-fluid benefitIcons" alt="MobileApp-benefit-05-futurristic" />
                            <Stack direction='horizontal' className='align-items-center justify-content-center h-50'><p>Cost Efficiency</p></Stack>
                        </div>
                    </Col>
                    <Col md={4} xs={6} lg={2}>
                        <div className="BenefitContent2">
                            <img src={benefit6} className="img-fluid benefitIcons" alt="MobileApp-benefit-06-futurristic" />
                            <Stack direction='horizontal' className='align-items-center justify-content-center h-50'><p>Cross-platform Compatibility</p></Stack>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
